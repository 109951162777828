import React, { useEffect, useState, Fragment } from 'react'
import axios from 'axios'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'

import Header from 'components/Headers/Header.js'
import AssingPatientModal from 'components/Modals/AssingPatientModal'
import SinglePatient from 'views/SinglePatient.js'

const Patients = (props) => {
  const history = useHistory()
  const [patientsTable, setPatientsTable] = useState([])
  const [selectedPatient, setSelectedPatient] = useState({})
  const [visibility, setVisibility] = useState(true)
  const [selectedPatientStat, setSelectedPatientStat] = useState([])
  const [selectedPatientWorldStat, setSelectedPatientWorldStat] = useState([])
  const [selectedPatientWorldStat2, setSelectedPatientWorldStat2] = useState([])
  const [selectedPatientWorldStat3, setSelectedPatientWorldStat3] = useState([])
  const [selectedPatientWorldStat4, setSelectedPatientWorldStat4] = useState([])
  const [assignModal, setAssignModal] = useState(false)

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/v1/api/patients`, {
        headers: {
          Authorization: `Bearer ${props.user.accessToken}`
        }
      })
      .then(function (response) {
        setPatientsTable(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [props, props.user])

  const goToSinglePatient = (patient) => {
    console.log(patient)
    history.push('/admin/patients')
    console.log(props)

    // history.push('/admin/single-patient')
  }

  const getSinglePatientData = (id) => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/v1/api/stats/general/${id}`, {
        headers: {
          Authorization: `Bearer ${props.user.accessToken}`
        }
      })
      .then(function (response) {
        setSelectedPatientStat(response.data)
        // console.log(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getSinglePatientWorldData = (world_id, id) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/v1/api/stats/${world_id}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${props.user.accessToken}`
          }
        }
      )
      .then(function (response) {
        setSelectedPatientWorldStat(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getSinglePatientWorldData2 = (world_id, id) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/v1/api/stats/${world_id}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${props.user.accessToken}`
          }
        }
      )
      .then(function (response) {
        setSelectedPatientWorldStat2(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getSinglePatientWorldData3 = (world_id, id) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/v1/api/stats/${world_id}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${props.user.accessToken}`
          }
        }
      )
      .then(function (response) {
        setSelectedPatientWorldStat3(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getSinglePatientWorldData4 = (world_id, id) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/v1/api/stats/${world_id}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${props.user.accessToken}`
          }
        }
      )
      .then(function (response) {
        setSelectedPatientWorldStat4(response.data)
        console.log(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const assignPatient = (patientId) => {
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/v1/api/patients/${patientId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${props.user.accessToken}`
          }
        }
      )
      .then(function (response) {
        toggleAssignModal()
        const newPatientsTable = [...patientsTable, response.data]
        setPatientsTable(newPatientsTable)
      })
      .catch(function (error) {
        console.log(error.response.data.error)
        toggleAssignModal()
      })
  }

  const toggleAssignModal = () => {
    setAssignModal(!assignModal)
  }

  if (visibility === true) {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Liste des Patients {visibility}</h3>
                  {props.user.role === 'admin' && (
                    <Button color="primary" onClick={() => toggleAssignModal()}>
                      Ajouter un patient
                    </Button>
                  )}
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Identifiant</th>

                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {patientsTable.map((patient) => (
                      <Fragment key={patient._id}>
                        <tr>
                          <td>{`${patient.uniqueId}`}</td>

                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setVisibility(false)
                                    setSelectedPatient(patient)
                                    getSinglePatientData(patient._id)
                                    getSinglePatientWorldData(1, patient._id)
                                    getSinglePatientWorldData2(2, patient._id)
                                    getSinglePatientWorldData3(3, patient._id)
                                    getSinglePatientWorldData4(4, patient._id)
                                  }}
                                >
                                  Consulter
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
        <AssingPatientModal
          modal={assignModal}
          assignPatient={assignPatient}
          closeModal={toggleAssignModal}
        />
      </>
    )
  } else {
    return (
      <SinglePatient
        patient={selectedPatient}
        getSinglePatientData={selectedPatientStat}
        getSinglePatientWorldData={selectedPatientWorldStat}
        getSinglePatientWorldData2={selectedPatientWorldStat2}
        getSinglePatientWorldData3={selectedPatientWorldStat3}
        getSinglePatientWorldData4={selectedPatientWorldStat4}
      ></SinglePatient>
    )
  }
}

export default Patients
