import React, { useEffect, useState, Fragment } from 'react'
import axios from 'axios'
import moment from 'moment'
import classnames from 'classnames'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import ExportCSV from '../components/Csv/ExportCSV'
import ExportCSVWorld from '../components/Csv/ExportCSVWorld'
import ReactToExcel from 'react-html-table-to-excel'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Col,
  CardHeader,
  Table,
  Container,
  Row,
  Button
} from 'reactstrap'

import Header from 'components/Headers/Header.js'

const SinglePatient = (props) => {
  const csvFileName = 'IDC-Statistiques'

  const [activeTab, setActiveTab] = useState('1')
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const getPatientDataActivity = () => {
    const myWorldData = Object.values(props.getSinglePatientWorldData)
    const mytab = []
    var mydata
    myWorldData.map((val, i) => {
      mytab[i] = val
    })
    console.log('tab length ' + mytab.length)
    console.log(mytab)

    return Object.values(mytab)
  }

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Statistiques</h3>
              </CardHeader>

              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      toggle('1')
                    }}
                  >
                    Général
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      toggle('2')
                    }}
                  >
                    Monde 1
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => {
                      toggle('3')
                    }}
                  >
                    Monde 2
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => {
                      toggle('4')
                    }}
                  >
                    Monde 3
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '5' })}
                    onClick={() => {
                      toggle('5')
                    }}
                  >
                    Monde 4
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <div className="col">
                      <Card className="shadow">
                        <CardHeader className="border-0">
                          <h3 className="mb-0">
                            Patient numéro
                            <span
                              style={{
                                color: '#0a7dc3',
                                fontWeight: '700',
                                textTransform: 'uppercase'
                              }}
                            >{`${props.patient.uniqueId}`}</span>
                          </h3>
                          {/* <ExportCSV
                            csvData={Object.values(props.getSinglePatientData)}
                            fileName={csvFileName}
                          /> */}
                          <ReactToExcel
                            variant="warning"
                            className="btn btn-secondary"
                            table="table-general"
                            filename={`${props.patient.uniqueId}` + '-général'}
                            sheet="Données"
                            buttonText="Exporter"
                          ></ReactToExcel>
                        </CardHeader>
                        <Table
                          id="table-general"
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr className="text-center">
                              <th> Connexion </th>

                              {/* <th scope="col">Connexion</th> */}
                              {Object.values(props.getSinglePatientData).map(
                                (test, i) => (
                                  <th scope="col" key={i}>
                                    Connexion
                                    {' ' +
                                      moment(test.createdAt).format(
                                        'YYYY-M-Do'
                                      )}
                                  </th>
                                )
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Dates/Horaires de connexion</td>
                              {Object.values(props.getSinglePatientData).map(
                                (test, i) => (
                                  <th scope="col" key={i}>
                                    {' ' +
                                      moment(test.createdAt).format(
                                        'YYYY-M-Do ,HH:mm:ss'
                                      )}
                                  </th>
                                )
                              )}
                            </tr>
                            <tr>
                              <td>Temps passé sur l'application (HH:MM:SS)</td>
                              {Object.values(props.getSinglePatientData).map(
                                (test, i) => (
                                  <td scope="col" key={i}>
                                    {/* {test.overallTimeSpent.toFixed(2)} */}
                                    {' ' +
                                      moment
                                        .utc(
                                          test.overallTimeSpent.toFixed(2) *
                                            1000
                                        )
                                        .format('HH:mm:ss')}
                                  </td>
                                )
                              )}
                            </tr>
                            <tr>
                              <td>Temps moyen passé sur un mini-jeu (sec)</td>
                              {Object.values(props.getSinglePatientData).map(
                                (test, i) => (
                                  <td scope="col" key={i}>
                                    {test.averageTimeSpent.toFixed(2)}
                                  </td>
                                )
                              )}
                            </tr>
                            <tr>
                              <td>Score moyen de réussite</td>
                              {Object.values(props.getSinglePatientData).map(
                                (test, i) => (
                                  <td scope="col" key={i}>
                                    {test.averagePassScore.toFixed(2)}
                                  </td>
                                )
                              )}
                            </tr>
                            <tr>
                              <td>Score moyen total de réussite</td>
                              {Object.values(props.getSinglePatientData).map(
                                (test, i) => (
                                  <td scope="col" key={i}>
                                    {test.totalAveragePassScore.toFixed(2)}
                                  </td>
                                )
                              )}
                            </tr>
                            <tr>
                              <td>
                                Nombre coquillages débloqués par connexion
                              </td>

                              {Object.values(props.getSinglePatientData).map(
                                (test, i) => (
                                  <td scope="col" key={i}>
                                    {test.shells}
                                  </td>
                                )
                              )}
                            </tr>
                            <tr>
                              <td>Nombre total de coquillages débloqués </td>

                              {Object.values(props.getSinglePatientData).map(
                                (test, i) => (
                                  <td scope="col" key={i}>
                                    {test.TotalShells}
                                  </td>
                                )
                              )}
                            </tr>
                          </tbody>
                        </Table>
                      </Card>
                    </div>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <div className="col">
                      <Card className="shadow">
                        <CardHeader className="border-0">
                          <h3 className="mb-0">
                            Patient numéro
                            <span
                              style={{
                                color: '#0a7dc3',
                                fontWeight: '700',
                                textTransform: 'uppercase'
                              }}
                            >{`${props.patient.uniqueId}`}</span>
                          </h3>
                          <ReactToExcel
                            variant="warning"
                            className="btn btn-secondary"
                            table="table-world-2"
                            filename={`${props.patient.uniqueId}` + '-monde1'}
                            sheet="Monde 1"
                            buttonText="Exporter"
                          ></ReactToExcel>
                        </CardHeader>
                        <Table
                          id="table-world-2"
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr className="text-center">
                              <th> Connexion </th>
                              {Object.values(
                                props.getSinglePatientWorldData
                              ).map((worldData, k) => (
                                <th key={k}>
                                  Connexion
                                  {' ' +
                                    moment(worldData.createdAt).format(
                                      'YYYY-M-Do'
                                    )}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {/* <tr>
                              <td>Horaire de connexion</td>
                              
                            </tr> */}
                            <tr>
                              <td>Choix des activités</td>
                              {Object.values(
                                props.getSinglePatientWorldData
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.CHOIX_ACTIVITE.map(
                                            (worldDataValue, i) => (
                                              <span key={i}>
                                                {
                                                  worldDataValue.successPercentage
                                                }
                                                <br />
                                              </span>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.CHOIX_ACTIVITE.map(
                                            (worldDataValue, j) => (
                                              <span key={j}>
                                                {worldDataValue.timeSpent.toFixed(
                                                  2
                                                )}
                                                <br />
                                              </span>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.CHOIX_ACTIVITE.map(
                                            (worldDataValue, k) => (
                                              <span key={k}>
                                                {isNaN(worldDataValue.latence)
                                                  ? worldDataValue.latence
                                                  : Number(
                                                      worldDataValue.latence
                                                    ).toFixed(2)}
                                                <br />
                                              </span>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Planification Niv1</td>
                              {Object.values(
                                props.getSinglePatientWorldData
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu (sec)</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.CHEVALET1.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.CHEVALET1.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.CHEVALET1.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Mini-jeu 1 "Danses Folkloriques"</td>
                              {Object.values(
                                props.getSinglePatientWorldData
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.DANSEUSE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.DANSEUSE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.DANSEUSE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Planification Niv2</td>
                              {Object.values(
                                props.getSinglePatientWorldData
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.CHEVALET2.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.CHEVALET2.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.CHEVALET2.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Mini-jeu 2 "Colliers de fleurs"</td>
                              {Object.values(
                                props.getSinglePatientWorldData
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.NECKLACE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.NECKLACE.map(
                                            (worldDataValue) => (
                                              <>
                                                {' '}
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.NECKLACE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Planification Niv3 </td>
                              {Object.values(
                                props.getSinglePatientWorldData
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.CHEVALET3.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.CHEVALET3.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.CHEVALET3.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </Table>
                      </Card>
                    </div>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <div className="col">
                      <Card className="shadow">
                        <CardHeader className="border-0">
                          <h3 className="mb-0">
                            Patient numéro
                            <span
                              style={{
                                color: '#0a7dc3',
                                fontWeight: '700',
                                textTransform: 'uppercase'
                              }}
                            >{`${props.patient.uniqueId}`}</span>
                          </h3>
                          <ReactToExcel
                            variant="warning"
                            className="btn btn-secondary"
                            table="table-world-3"
                            filename={`${props.patient.uniqueId}` + '-monde2'}
                            sheet="Monde 2"
                            buttonText="Exporter"
                          ></ReactToExcel>
                        </CardHeader>
                        <Table
                          id="table-world-3"
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr className="text-center">
                              <th> Connexion </th>
                              {Object.values(
                                props.getSinglePatientWorldData2
                              ).map((worldData, k) => (
                                <th key={k}>
                                  Connexion
                                  {' ' +
                                    moment(worldData.createdAt).format(
                                      'YYYY-M-Do'
                                    )}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {/* <tr>
                              <td>Horaire de connexion</td>
                              
                            </tr> */}
                            <tr>
                              <td>Test d'association</td>
                              {Object.values(
                                props.getSinglePatientWorldData2
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.BATIMENT.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.BATIMENT.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.BATIMENT.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Constitution paquetage</td>
                              {Object.values(
                                props.getSinglePatientWorldData2
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.PAQUETAGE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.PAQUETAGE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.PAQUETAGE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Balades en bateau</td>
                              {Object.values(
                                props.getSinglePatientWorldData2
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.CONDUIRE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.CONDUIRE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.CONDUIRE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Mini-jeu 1 Ile aux poissons colorés</td>
                              {Object.values(
                                props.getSinglePatientWorldData2
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.poisson.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.poisson.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.poisson.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Mini-jeu 2 Rocher sirène envoutante</td>
                              {Object.values(
                                props.getSinglePatientWorldData2
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.rochers.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.rochers.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.rochers.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Mini-jeu 3 Ile aux méduses</td>
                              {Object.values(
                                props.getSinglePatientWorldData2
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.meduse.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.meduse.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.meduse.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Mini-jeu 4 Chant de la sirène</td>
                              {Object.values(
                                props.getSinglePatientWorldData2
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.sirene.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.sirene.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.sirene.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </Table>
                      </Card>
                    </div>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <div className="col">
                      <Card className="shadow">
                        <CardHeader className="border-0">
                          <h3 className="mb-0">
                            Patient numéro
                            <span
                              style={{
                                color: '#0a7dc3',
                                fontWeight: '700',
                                textTransform: 'uppercase'
                              }}
                            >{`${props.patient.uniqueId}`}</span>
                          </h3>
                          <ReactToExcel
                            variant="warning"
                            className="btn btn-secondary"
                            table="table-world-4"
                            filename={`${props.patient.uniqueId}` + '-monde3'}
                            sheet="Monde 3"
                            buttonText="Exporter"
                          ></ReactToExcel>
                        </CardHeader>
                        <Table
                          id="table-world-4"
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr className="text-center">
                              <th> Connexion </th>
                              {Object.values(
                                props.getSinglePatientWorldData3
                              ).map((worldData, k) => (
                                <th key={k}>
                                  Connexion
                                  {' ' +
                                    moment(worldData.createdAt).format(
                                      'YYYY-M-Do'
                                    )}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {/* <tr>
                              <td>Horaire de connexion</td>
                              
                            </tr> */}
                            <tr>
                              <td>Préparation tempête</td>
                              {Object.values(
                                props.getSinglePatientWorldData3
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.MAGASIN.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.MAGASIN.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.MAGASIN.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Trouvez les bungalows</td>
                              {Object.values(
                                props.getSinglePatientWorldData3
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.GPS.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.GPS.map(
                                            (worldDataValue) => (
                                              <>
                                                {' '}
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.GPS.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Mini-jeu 1 : La caserne de pompier</td>
                              {Object.values(
                                props.getSinglePatientWorldData3
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.FIRE_HYD.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.FIRE_HYD.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.FIRE_HYD.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Arrêter l'alarme et accéder à la personne</td>
                              {Object.values(
                                props.getSinglePatientWorldData3
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.BANGALOW2.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.BANGALOW2.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.BANGALOW2.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Récupérez de l'énergie</td>
                              {Object.values(
                                props.getSinglePatientWorldData3
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.NINJA.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.NINJA.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.NINJA.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Mini-jeu 2 Incendie</td>
                              {Object.values(
                                props.getSinglePatientWorldData3
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.MINI_FIRE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.MINI_FIRE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.MINI_FIRE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Remise en ordre du club</td>
                              {Object.values(
                                props.getSinglePatientWorldData3
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.PUZZLE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.PUZZLE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.PUZZLE.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </Table>
                      </Card>
                    </div>
                  </Row>
                </TabPane>
                <TabPane tabId="5">
                  <Row>
                    <div className="col">
                      <Card className="shadow">
                        <CardHeader className="border-0">
                          <h3 className="mb-0">
                            Patient numéro
                            <span
                              style={{
                                color: '#0a7dc3',
                                fontWeight: '700',
                                textTransform: 'uppercase'
                              }}
                            >{`${props.patient.uniqueId}`}</span>
                          </h3>
                          <ReactToExcel
                            variant="warning"
                            className="btn btn-secondary"
                            table="table-world-5"
                            filename={`${props.patient.uniqueId}` + '-monde4'}
                            sheet="Monde 4"
                            buttonText="Exporter"
                          ></ReactToExcel>
                        </CardHeader>
                        <Table
                          id="table-world-5"
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr className="text-center">
                              <th> Connexion </th>
                              {Object.values(
                                props.getSinglePatientWorldData4
                              ).map((worldData, k) => (
                                <th key={k}>
                                  Connexion
                                  {' ' +
                                    moment(worldData.createdAt).format(
                                      'YYYY-M-Do'
                                    )}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {/* <tr>
                              <td>Horaire de connexion</td>
                              
                            </tr> */}
                            <tr>
                              <td>Apprentissage du pilotage niveau 1 </td>
                              {Object.values(
                                props.getSinglePatientWorldData4
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.BREVET1.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.BREVET1.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.BREVET1.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Apprentissage du pilotage niveau 2 </td>
                              {Object.values(
                                props.getSinglePatientWorldData4
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.BREVET2.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.BREVET2.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.BREVET2.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Pilotage niveau 1</td>
                              {Object.values(
                                props.getSinglePatientWorldData4
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.AVION1.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.AVION1.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.AVION1.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Pilotage niveau 2</td>
                              {Object.values(
                                props.getSinglePatientWorldData4
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.AVION2.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.AVION2.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.AVION2.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Pilotage niveau 3</td>
                              {Object.values(
                                props.getSinglePatientWorldData4
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.AVION3.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.AVION3.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.AVION3.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <td>Pilotage niveau 4</td>
                              {Object.values(
                                props.getSinglePatientWorldData4
                              ).map((worldData, t) => (
                                <td key={t}>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th>Pourcentage de réussite</th>
                                        <th>Durée passée (sec)</th>
                                        <th>Latence en jeu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {worldData.AVION4.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  worldDataValue.successPercentage
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.AVION4.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  Number(
                                                    worldDataValue.timeSpent.toFixed(
                                                      2
                                                    )
                                                  )
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {worldData.AVION4.map(
                                            (worldDataValue) => (
                                              <>
                                                {JSON.stringify(
                                                  isNaN(worldDataValue.latence)
                                                    ? worldDataValue.latence
                                                    : Number(
                                                        worldDataValue.latence
                                                      ).toFixed(2)
                                                ).concat(' ')}
                                                <br />
                                              </>
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </Table>
                      </Card>
                    </div>
                  </Row>
                </TabPane>
              </TabContent>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default SinglePatient
