/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  FormFeedback
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useAuth } from '../use-auth'

const ForgetPassword = () => {
  const auth = useAuth()
  const { register, handleSubmit, errors } = useForm()
  const onPasswordChangeRequest = ({ email }) => {
    auth.sendPasswordResetEmail(email)
  }

  useEffect(() => {
    return () => {
      if (auth.forgetPasswordError) {
        auth.cleanPasswordResetError()
      }
    }
  })
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {auth.forgetPasswordSuccess && (
              <div className="text-center text-muted mb-4">
                <small>
                  Check your inbox for the next steps. If you don't receive an
                  email, and it's not in your spam folder this could mean you
                  signed up with a different address.
                </small>
              </div>
            )}
            {!auth.forgetPasswordSuccess && (
              <>
                <div className="text-center text-muted mb-4">
                  <small>
                    Entrez votre adresse e-mail ci-dessous et nous vous
                    enverrons un lien pour réinitialiser votre mot de passe.
                  </small>
                </div>
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        invalid
                        type="email"
                        autoComplete="new-email"
                        name="email"
                        innerRef={register({ required: true })}
                      />
                      {errors.email && (
                        <FormFeedback>Email is required</FormFeedback>
                      )}
                    </InputGroup>
                  </FormGroup>
                  {auth.forgetPasswordError && (
                    <div>{auth.forgetPasswordError.error}</div>
                  )}

                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={handleSubmit(onPasswordChangeRequest)}
                    >
                      Envoyer
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link className="text-light" to="/auth/login">
              <small>Connexion</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default ForgetPassword
