import React, { Component } from 'react'
import { Button, Container, Row, Col } from 'reactstrap'

const Dashboardheader = (props) => {
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: '600px',
          // backgroundImage:
          //   "url(" + require("assets/img/theme/profile-cover.jpg") + ")",
          backgroundSize: 'cover',
          backgroundPosition: 'center top'
        }}
      >
        <span className="mask bg-gradient-default opacity-8" />

        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
              <h1 className="display-2 text-white">Bienvenue ! </h1>
              <p className="text-white mt-0 mb-5">
                Sur cette plateforme vous pouvez gérer les accès à la plateforme
                et visualiser la progression des patients.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default Dashboardheader
