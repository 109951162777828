import React, { useState, useEffect, useContext, createContext } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'

const authContext = createContext()

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null)
  const [loginError, setLoginError] = useState(null)
  const [forgetPasswordError, setForgetPasswordError] = useState(null)
  const [forgetPasswordSuccess, setForgetPasswordSuccess] = useState(null)
  const [activateAccountError, setActivateAccountError] = useState(null)
  const [activateAccountSuccess, setActivateAccountSuccess] = useState(null)
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(null)
  const [resetPasswordError, setResetPasswordError] = useState(null)

  const signin = (uniqueId, password) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/login`, {
        uniqueId,
        password
      })
      .then(function (response) {
        setUser(response.data)
        console.log(response.data)
        setLoginError(null)
        localStorage.setItem('user', JSON.stringify(response.data))
      })
      .catch(function (error) {
        setLoginError(error)
        console.log(error)
      })
  }

  const signout = () => {
    setUser(null)
    localStorage.removeItem('user')
  }

  const sendPasswordResetEmail = (email) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/forget-password`, {
        email
      })
      .then(function (response) {
        setForgetPasswordSuccess(true)
      })
      .catch(function (error) {
        setForgetPasswordError(error.response.data)
        console.log(error)
      })
  }

  const activateAccount = (password, token) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/email-activation`, {
        password,
        token
      })
      .then(function () {
        setActivateAccountSuccess(true)
        setActivateAccountError(null)
      })
      .catch(function (error) {
        setActivateAccountError(error.response.data)
        setActivateAccountSuccess(null)
        console.log(error)
      })
  }

  const cleanPasswordResetError = () => {
    setForgetPasswordError(null)
  }

  const resetPassword = (password, token) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/reset-password`, {
        password,
        token
      })
      .then(function () {
        setResetPasswordSuccess(true)
        setResetPasswordError(null)
      })
      .catch(function (error) {
        setResetPasswordSuccess(null)
        setResetPasswordError(error.response.data)
        console.log(error)
      })
  }

  useEffect(() => {
    getUser()
  }, [])

  function getUser() {
    const userStorage = localStorage.getItem('user')
    if (!userStorage) {
      return null
    }

    const user = JSON.parse(userStorage)
    const decodedToken = jwt_decode(user.accessToken)
    const now = Math.floor(Date.now() / 1000)
    // compare the expiry time of the token with the current time
    if (now > decodedToken.exp) {
      // If the item is expired, delete the item from storage
      localStorage.removeItem('user')
    }
    setUser(user)
  }
  // Return the user object and auth methods
  return {
    user,
    loginError,
    signin,
    signout,
    sendPasswordResetEmail,
    activateAccount,
    forgetPasswordError,
    cleanPasswordResetError,
    forgetPasswordSuccess,
    activateAccountError,
    activateAccountSuccess,
    resetPassword,
    resetPasswordSuccess,
    resetPasswordError
  }
}
