import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Container,
  FormFeedback,
  Label,
  Row,
  Col
} from 'reactstrap'
export default function AddAdminModal({ modal, addAdmin, closeModal }) {
  const { register, handleSubmit, errors } = useForm()
  const onAddAdmin = ({ clinic, firstname, lastname, profession, email }) => {
    const admin = {
      uniqueId: email,
      email,
      firstname,
      lastname,
      profession,
      clinic: Number(clinic)
    }
    addAdmin(admin)
  }
  return (
    <Modal isOpen={modal} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>ADMIN</ModalHeader>
      {/* <ModalBody>Are you sure you want to add a new user ?</ModalBody> */}
      <Card>
        <CardBody>
          <Form role="form">
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      Prénom
                    </label>
                    <Input
                      className="form-control-alternative"
                      placeholder="Prénom"
                      name="lastname"
                      type="text"
                      invalid
                      innerRef={register({ required: true })}
                    />
                    {errors.lastname && (
                      <FormFeedback>Champ obligatoire</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-last-name"
                    >
                      Nom
                    </label>

                    <Input
                      className="form-control-alternative"
                      placeholder="Nom"
                      invalid
                      type="text"
                      name="firstname"
                      innerRef={register({ required: true })}
                    />
                    {errors.firstname && (
                      <FormFeedback>Champ obligatoire</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-email">
                      Adresse Email
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Email"
                        invalid
                        type="email"
                        autoComplete="new-email"
                        name="email"
                        innerRef={register({
                          required: 'Email is required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'invalid email address'
                          }
                        })}
                      />
                      {errors.email && (
                        <FormFeedback>{errors.email.message}</FormFeedback>
                      )}
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Profession
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <i className="ni ni-email-83" /> */}
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        placeholder="Profession"
                        invalid
                        type="select"
                        name="profession"
                        innerRef={register({ required: true })}
                      >
                        <option>Investigateur</option>
                        <option>Arc investigateur</option>
                      </Input>
                      {errors.profession && (
                        <FormFeedback>Champ obligatoire</FormFeedback>
                      )}
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      Centre
                    </label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        type="select"
                        name="clinic"
                        id="select"
                        innerRef={register({ required: true })}
                      >
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit(onAddAdmin)}>
          Ajouter
        </Button>{' '}
        <Button color="secondary" onClick={closeModal}>
          Annuler
        </Button>
      </ModalFooter>
    </Modal>
  )
}
// TODO
// form here that returns the fields
// form with react hooks form
