/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, Fragment } from 'react'
import axios from 'axios'
import moment from 'moment'
// reactstrap components
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Button
} from 'reactstrap'
// core components
import Header from 'components/Headers/Header.js'
// import UserHeader from 'components/Headers/UserHeader.js'
import DeleteModal from 'components/Modals/DeleteAdminModal'
import EditModal from 'components/Modals/EditAdminModal'
import AddModal from 'components/Modals/AddAdminModal'

const Admins = (props) => {
  const [adminsTable, setAdminsTable] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [addModal, setAddModal] = useState(false)

  const [selectedAdmin, setSelectedAdmin] = useState({})

  const toggleDeleteModal = (admin) => {
    setDeleteModal(!deleteModal)
    setSelectedAdmin(admin)
  }

  const closeDeleteModal = () => {
    setDeleteModal(!deleteModal)
    setSelectedAdmin({})
  }

  const toggleEditModal = (admin) => {
    setEditModal(!editModal)
    setSelectedAdmin(admin)
  }

  const closeEditModal = () => {
    setEditModal(!editModal)
    setSelectedAdmin({})
  }
  const toggleAddModal = () => {
    setAddModal(!addModal)
  }

  const closeAddModal = () => {
    setAddModal(!addModal)
  }
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/v1/api/users?role=admin`, {
        headers: {
          Authorization: `Bearer ${props.user.accessToken}`
        }
      })
      .then(function (response) {
        setAdminsTable(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [props, props.user])

  const deleteAdmin = (id) => {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/v1/api/users/admins/${id}`, {
        headers: {
          Authorization: `Bearer ${props.user.accessToken}`
        }
      })
      .then(function () {
        const returnedTable = adminsTable.filter((admin) => admin._id !== id)
        setAdminsTable(returnedTable)
        closeDeleteModal()
      })
      .catch(function (error) {
        console.log(error)
        closeDeleteModal()
      })
  }
  const editAdmin = (id, body) => {
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/v1/api/users/admins/${id}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${props.user.accessToken}`
          }
        }
      )
      .then(function (response) {
        const returnedTable = adminsTable.filter((admin) => admin._id !== id)
        returnedTable.push(response.data)
        setAdminsTable(returnedTable)
        closeEditModal()
      })
      .catch(function (error) {
        console.log(error)
        closeEditModal()
      })
  }

  const addAdmin = (admin) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/v1/api/users/admins`, admin, {
        headers: {
          Authorization: `Bearer ${props.user.accessToken}`
        }
      })
      .then(function () {
        closeAddModal()
      })
      .catch(function (error) {
        console.log(error)
        closeAddModal()
      })
  }
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Liste des Admins</h3>
                <Button color="primary" onClick={() => toggleAddModal()}>
                  Ajouter
                </Button>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nom</th>
                    <th scope="col">Email</th>
                    <th scope="col">Profession</th>
                    <th scope="col">Centre</th>
                    <th scope="col">Créé le</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {adminsTable.map((admin) => (
                    <Fragment key={admin._id}>
                      <tr>
                        <td>{`${admin.firstname} ${admin.lastname}`}</td>
                        <td>{admin.email}</td>
                        <td>{admin.profession}</td>
                        <td>{admin.clinic}</td>
                        <td>{moment(admin.createdAt).format('DD-MM-YYYY')}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault()
                                  toggleEditModal(admin)
                                }}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault()
                                  toggleDeleteModal(admin)
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      <DeleteModal
        modal={deleteModal}
        admin={selectedAdmin}
        deleteAdmin={deleteAdmin}
        closeModal={closeDeleteModal}
      />
      <EditModal
        modal={editModal}
        admin={selectedAdmin}
        editAdmin={editAdmin}
        closeModal={closeEditModal}
      />
      <AddModal
        modal={addModal}
        addAdmin={addAdmin}
        closeModal={closeAddModal}
      />
    </>
  )
}

export default Admins
