/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from 'views/Index.js'
import Profile from 'views/Profile.js'
// import Maps from 'views/Maps.js'
import Admins from 'views/Admins'
import Patients from 'views/Patients'
// import Register from "views/Register.js"
import ForgetPassword from 'views/ForgetPassword'
import ResetPassword from 'views/ResetPassword'
import ActivateAccount from 'views/ActivateAccount'
import Login from 'views/Login.js'
// import Tables from 'views/Tables.js'
// import Icons from 'views/Icons.js'
import SinglePatient from 'views/SinglePatient'

var routes = [
  {
    path: '/index',
    name: 'Tableau de bord',
    icon: 'ni ni-tv-2 text-primary',
    component: Index,
    layout: '/admin'
  },
  // {
  //   path: '/icons',
  //   name: 'Icons',
  //   icon: 'ni ni-planet text-blue',
  //   component: Icons,
  //   layout: '/admin'
  // },
  // {
  //   path: '/maps',
  //   name: 'Maps',
  //   icon: 'ni ni-pin-3 text-orange',
  //   component: Maps,
  //   layout: '/admin'
  // },
  {
    path: '/user-profile',
    name: 'Profil',
    icon: 'ni ni-circle-08 text-yellow',
    component: Profile,
    layout: '/admin'
  },
  // {
  //   path: '/tables',
  //   name: 'Tables',
  //   icon: 'ni ni-bullet-list-67 text-red',
  //   component: Tables,
  //   layout: '/admin'
  // },
  {
    path: '/admins',
    name: 'Admins',
    icon: 'ni ni-circle-08 text-blue',
    component: Admins,
    layout: '/admin',
    superadmin: true
  },
  {
    path: '/patients',
    name: 'Patients',
    icon: 'ni ni-circle-08 text-red',
    component: Patients,
    layout: '/admin'
  },
  {
    path: '/single-patient',
    component: SinglePatient,
    layout: '/admin'
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth'
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    icon: 'ni ni-key-25 text-info',
    component: ForgetPassword,
    layout: '/auth'
  },
  {
    path: '/activate/:token',
    name: 'ActivateAccount',
    icon: 'ni ni-key-25 text-info',
    component: ActivateAccount,
    layout: '/auth'
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    icon: 'ni ni-key-25 text-info',
    component: ResetPassword,
    layout: '/auth'
  }
]
export default routes
