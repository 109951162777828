import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from './use-auth.js'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useAuth()
  return (
    <Route
      {...rest}
      component={(props) =>
        auth.user ? (
          <Component {...props} user={auth.user} />
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  )
}

export default PrivateRoute
