/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Redirect } from 'react-router-dom'

import 'assets/plugins/nucleo/css/nucleo.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'assets/scss/argon-dashboard-react.scss'

import AdminLayout from 'layouts/Admin.js'
import SinglePatient from 'views/SinglePatient'
import AuthLayout from 'layouts/Auth.js'
import { ProvideAuth } from './use-auth.js'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'

ReactDOM.render(
  <ProvideAuth>
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/admin" component={AdminLayout} />
        <PublicRoute path="/auth" component={AuthLayout} />
        <Redirect from="/" to="/auth/login" />
      </Switch>
    </BrowserRouter>
  </ProvideAuth>,
  document.getElementById('root')
)
