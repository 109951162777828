import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from "./use-auth.js";

const PublicRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {
    const auth = useAuth()
    return (
    <Route {...rest} component={(props) => (
        auth.user ? (
        <Redirect to="/admin/index" />
      ) : (
          <Component {...props} />
        )
    )} />
  );
      }



export default PublicRoute