import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
export default function DeleteAdminModal({
  modal,
  admin,
  deleteAdmin,
  closeModal
}) {
  return (
    <Modal isOpen={modal} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        Delete {`${admin.firstname} ${admin.lastname}`}
      </ModalHeader>
      <ModalBody>
        Are you sure you want to delete {`${admin.firstname} ${admin.lastname}`}
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => deleteAdmin(admin._id)}>
          Delete
        </Button>{' '}
        <Button color="secondary" onClick={closeModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
