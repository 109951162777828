import React from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  FormFeedback,
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap'

export default function EditAdminModal({
  modal,
  admin,
  editAdmin,
  closeModal
}) {
  const { register, handleSubmit, errors } = useForm()
  const onEditAdmin = ({ password, profession }) => {
    const body = {
      password,
      profession
    }
    editAdmin(admin._id, body)
  }

  return (
    <Modal isOpen={modal} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>
        Edit {`${admin.firstname} ${admin.lastname}`}
      </ModalHeader>
      <ModalBody>
        Are you sure you want to Edit {`${admin.firstname} ${admin.lastname}`}
      </ModalBody>
      <Card>
        <CardBody>
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="profession">
                      Profession
                    </label>
                    <Input
                      type="select"
                      name="profession"
                      id="profession"
                      innerRef={register({ required: false })}
                    >
                      <option>Investigateur</option>
                      <option>Arc investigateur</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-3">
                    <label className="form-control-label" htmlFor="profession">
                      Mot de passe
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        placeholder="Password"
                        invalid
                        type="password"
                        autoComplete="new-password"
                        name="password"
                        innerRef={register({
                          required: 'You must specify a password',
                          pattern: {
                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
                            message:
                              'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number'
                          }
                        })}
                      />
                      {errors.password && (
                        <FormFeedback>{errors.password.message}</FormFeedback>
                      )}
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
      <ModalFooter>
        <Button
          type="submit"
          color="primary"
          onClick={handleSubmit(onEditAdmin)}
        >
          Modifier
        </Button>{' '}
        <Button color="secondary" onClick={closeModal}>
          Annuler
        </Button>
      </ModalFooter>
    </Modal>
  )
}
