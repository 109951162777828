/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useAuth } from '../use-auth'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  FormFeedback,
  Row
} from 'reactstrap'

const ActivateAccount = (props) => {
  const auth = useAuth()
  const { register, handleSubmit, errors, watch } = useForm()
  const password = useRef({})
  password.current = watch('password', '')
  const onPasswordReset = (data) => {
    auth.resetPassword(data.password, props.match.params.token)
  }
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {auth.resetPasswordSuccess && (
              <div className="text-center text-muted mb-4">
                <small>Your password has been changed, please login</small>
                <Link to="/auth/login">
                  <Button className="my-4" color="primary" type="button">
                    Login
                  </Button>
                </Link>
              </div>
            )}
            {auth.resetPasswordError && (
              <div className="text-center text-muted mb-4">
                <small>
                  The password reset link is invalid or has expired, try again
                </small>
                <Link to="/auth/forget-password">
                  <Button className="my-4" color="primary" type="button">
                    Forget password
                  </Button>
                </Link>
              </div>
            )}
            {!auth.resetPasswordSuccess && !auth.resetPasswordError && (
              <>
                <div className="text-center text-muted mb-4">
                  <small>Reset your password</small>
                </div>
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        placeholder="Password"
                        invalid
                        type="password"
                        autoComplete="new-password"
                        name="password"
                        innerRef={register({
                          required: 'You must specify a password',
                          pattern: {
                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
                            message:
                              'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number'
                          }
                        })}
                      />
                      {errors.password && (
                        <FormFeedback>{errors.password.message}</FormFeedback>
                      )}
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        placeholder="Confirm password"
                        invalid
                        type="password"
                        autoComplete="new-password"
                        name="confirmPassword"
                        innerRef={register({
                          validate: (value) =>
                            value === password.current ||
                            'The passwords do not match'
                        })}
                      />
                      {errors.confirmPassword && (
                        <FormFeedback>
                          {errors.confirmPassword.message}
                        </FormFeedback>
                      )}
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={handleSubmit(onPasswordReset)}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link className="text-light" to="/auth/login">
              <small>Sign in</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default ActivateAccount
