/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useAuth } from '../use-auth'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  FormFeedback
} from 'reactstrap'

const Login = () => {
  const auth = useAuth()
  const { register, handleSubmit, errors } = useForm()
  const onLogin = (data) => {
    auth.signin(data.email, data.password)
  }
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Connectez-vous à votre compte</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    invalid
                    type="email"
                    autoComplete="new-email"
                    name="email"
                    innerRef={register({ required: true })}
                  />
                  {errors.email && (
                    <FormFeedback>Email is required</FormFeedback>
                  )}
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>

                  <Input
                    placeholder="Password"
                    invalid
                    type="password"
                    autoComplete="new-password"
                    name="password"
                    innerRef={register({ required: true })}
                  />
                  {errors.password && (
                    <FormFeedback>Password is required</FormFeedback>
                  )}
                </InputGroup>
              </FormGroup>
              {auth.loginError && <div>Login problems try again</div>}

              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={handleSubmit(onLogin)}
                >
                  Se connecter
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link className="text-light" to="/auth/forget-password">
              <small>Mot de passe oublié?</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default Login
