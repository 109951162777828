import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  FormFeedback,
  Row,
  Col
} from 'reactstrap'
export default function AssingPatientModal({
  modal,
  assignPatient,
  closeModal
}) {
  const { register, handleSubmit, errors } = useForm()
  const onAssignAdmin = ({ id }) => {
    assignPatient(id)
  }
  return (
    <Modal isOpen={modal} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Assigner patient</ModalHeader>
      <ModalBody>Merci d'ajouter l'identifiant du patient</ModalBody>
      <Card>
        <CardBody>
          <Form role="form">
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-last-name"
                    >
                      ID
                    </label>

                    <Input
                      className="form-control-alternative"
                      placeholder="ID"
                      invalid
                      type="text"
                      name="id"
                      innerRef={register({ required: true })}
                    />
                    {errors.id && (
                      <FormFeedback>Champ obligatoire</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit(onAssignAdmin)}>
          Assigner
        </Button>{' '}
        <Button color="secondary" onClick={closeModal}>
          Annuler
        </Button>
      </ModalFooter>
    </Modal>
  )
}
